import React, {Component, Fragment} from 'react';
import {observer} from 'mobx-react';
import {action, observable} from 'mobx';
import api from "../utils/api";
import Loader from "../components/Loader";
import Store from "../Store";
import Loadable from "../components/Loadable";
import moment from "moment";
import 'moment/locale/ru';
import Icon from "../components/Icon";
import {Button, Modal} from "react-bootstrap";

moment.locale('ru');

@observer
class Calendar extends Component {

	@observable year;
	@observable month;
	@observable ready = false;
	@observable loading = false;
	@observable data = {};

	@observable showModal = false;
	@observable cell = {};

	async componentDidMount() {
		if(Store.calendar) {
			this.data = Store.calendar;
			this.month = this.data.month;
			this.year = this.data.year;
		} else {
			await this.fetch();
		}
		this.ready = true;
	}

	@action fetch = async () => {
		try {
			this.loading = true;
			this.data = Store.calendar = await api(`calendar`, {
				year: this.year,
				month: this.month
			});
			this.year = this.data.year;
			this.month = this.data.month;
		} catch (e) {
			await Store.modalAlert(e.message);
		} finally {
			this.loading = false;
		}
	};


	dec = async () => {
		let month = Number(this.month);
		let year = Number(this.year);
		month--;
		if(month === 0) {
			month = 12;
			year--;
		}
		this.month = String((month < 10 ? '0' : '') + month);
		this.year = String(year);
		await this.fetch();
	};


	inc = async () => {
		let month = Number(this.month);
		let year = Number(this.year);
		console.log(month, year);
		month++;
		if(month === 13) {
			month = 1;
			year++;
		}
		this.month = String((month < 10 ? '0' : '') + month);
		this.year = String(year);
		await this.fetch();
	};

	onCellClick = (cell) => {
		if(!Store.user.is_admin) return false;
		if(!cell.date) return false;
		this.cell = {...cell};
		this.showModal = true;
	};

	@action closeModal = () => {
		this.showModal = false;
	};

	@action edit = (e) => {
		const cell = {...this.cell};
		const key = e.target.name;
		let value = e.target.value;
		value = value || null;

		if(key === 'type') {
			cell.hours = {
				workday: 8,
				short: 7,
				holiday: 0,
				dayoff: 0
			}[value];
		}

		cell[key] = value;
		this.cell = cell;
	};

	@action submit = async (e) => {
		e.preventDefault();
		try {
			this.loading = true;
			const day = {...this.cell};
			delete day.today;
			delete day.bdates;
			await api(`calendar/${this.cell.date}/update`, { day });
			this.showModal = false;
			await this.fetch();
		} catch (e) {
			await Store.modalAlert(e.message);
		} finally {
			this.loading = false;
		}
	};


	render() {
		if (!this.ready) return <Loader/>;
		const cells = [];
		for(let x = 0; x < this.data.offset; x++) {
			cells.push({ empty: true });
		}
		for(const day of this.data.calendar){
			cells.push(day);
		}

		return (
			<Loadable loading={this.loading}>

				<div align="center">
					<div className="d-inline-block">
						<div className="input-group" style={{width:320}}>
							<div className="input-group-prepend">
								<button className="btn btn-secondary" onClick={this.dec}><Icon icon="arrow-left"/></button>
							</div>
							<select className="form-control" value={this.month} onChange={e=>{this.month=e.target.value;this.fetch()}}>
								<option value="01">Январь</option>
								<option value="02">Февраль</option>
								<option value="03">Март</option>
								<option value="04">Апрель</option>
								<option value="05">Май</option>
								<option value="06">Июнь</option>
								<option value="07">Июль</option>
								<option value="08">Август</option>
								<option value="09">Сентябрь</option>
								<option value="10">Октябрь</option>
								<option value="11">Ноябрь</option>
								<option value="12">Декабрь</option>
							</select>
							<input className="form-control" type="number" value={this.year} onChange={(e)=>{this.year=e.target.value;this.fetch()}} min="2016" max="2050" step={1}/>
							<div className="input-group-append">
								<button className="btn btn-secondary" onClick={this.inc}><Icon icon="arrow-right"/></button>
							</div>
						</div>
					</div>
				</div>

				<div className="calendar mt-3 pb-3">
					{cells.map((cell, idx) => <div onClick={()=>this.onCellClick(cell)} key={idx} className={`calendar__item ${cell.today ? 'calendar__item_today' : ''} calendar__item_${cell.type || 'empty'}`}>
						{Boolean(cell.date) && <Fragment>
							<div className="calendar__item_day">{moment(cell.date).format('D')}</div>
							<div className="calendar__item_week">{moment(cell.date).format('dd')}.</div>
							{cell.hours > 0 && <div className="calendar__item_hours">
								<Icon icon="clock-o"/> {cell.hours} ч.</div>}
							<div className="calendar__item_comment">{cell.comment}</div>
							{cell.bdates.map(u=><div className="calendar__item_comment"><i className="fa fa-birthday-cake"/> {u.first_name} {u.last_name}</div>)}
						</Fragment>}
					</div>)}
					<div className="clearfix"/>
				</div>

				<Modal show={this.showModal} onHide={this.closeModal}>
					<form onSubmit={this.submit}>
						<Modal.Header closeButton>
							<Modal.Title><Icon icon="calendar"/> {moment(this.cell.date).format('DD.MM.YYYY')}</Modal.Title>
						</Modal.Header>
						<Loadable loading={this.loading}>
							<Modal.Body>
								<div className="row">
									<div className="col">
										<div className="form-group">
											<label>Тип</label>
											<select required onChange={this.edit} name="type" value={this.cell.type} className="form-control">
												<option value="workday">Рабочий день</option>
												<option value="short">Сокращенный рабочий день</option>
												<option value="dayoff">Выходной</option>
												<option value="holiday">Праздничный день</option>
											</select>
										</div>
									</div>
									{(this.cell.type === 'workday' || this.cell.type === 'short') &&
										<div className="col-4">
											<div className="form-group">
												<label>Рабочие часы</label>
												<input type="number" className="form-control"
													 min={0} max={24} name="hours"
													 required
													 value={this.cell.hours || ''}
													 onChange={this.edit}
													 placeholder="8"
												/>
											</div>
										</div>
									}
								</div>
								<div className="form-group">
									<label>Комментарий</label>
									<input type="text" name="comment" className="form-control"
										value={this.cell.comment || ''}
										onChange={this.edit}
										maxLength={255}
									/>
								</div>
							</Modal.Body>
						</Loadable>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.closeModal} type="button">
								Отмена
							</Button>
							<Button disabled={this.loading} variant="primary" type="submit">
								<Icon icon="save"/>
								Сохранить
							</Button>
						</Modal.Footer>
					</form>
				</Modal>
			</Loadable>
		)
	}
}

export default Calendar;
