import React, {Component} from 'react';
import PropTypes from 'prop-types';
import stub from '../images/no-pic.svg';
import {Link} from "react-router-dom";
class User extends Component {

	static defaultProps = {
		onClick: null,
	};

	static propTypes = {
		user: PropTypes.object.isRequired,
		onClick: PropTypes.func,
	};

	component() {
		const user = this.props.user || {};
		let name = `${user.first_name || ''} ${user.last_name || ''}`.trim();
		if(!name) name = user.email;
		if(!name) name = `Пользователь ${user.id}`;
		return (
			<div className="user d-flex" style={{ cursor: 'pointer '}}>
				<div className="user__avatar position-relative">
					<img src={user.avatar ? user.avatar.url : stub} alt={name}
							 style={{ width: 48, height: 48, borderRadius: 24, border: '1px solid #efefef',
								 backgroundColor: '#fff', padding: 2 }}/>
					<div className={`user__status ${user.online ? 'user__status__online' : 'user__status__offline'}`}/>
				</div>
				<div className="user__cred ml-2">
					<div className="user__name">{name}</div>
					<div className="user__position small text-muted">
						{user.position || 'Сотрудник'}
						{Boolean(user.salary) && <span>, {user.salary.total} $</span>}

					</div></div>
			</div>
		);
	}

	render() {
		const user = this.props.user || {};
		return (
			this.props.onClick ?
				<div onClick={() => this.props.onClick(user)}>
					{this.component()}
				</div>
				:
				<Link style={{ textDecoration: 'none' }} to={'/users/' + user.id}>
					{this.component()}
				</Link>
		);
	}
}

export default User;
