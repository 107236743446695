import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Loadable extends Component {

	static defaultProps = {
		loading: false
	};

	static propTypes = {
		loading: PropTypes.bool,
	};

	render() {
		return (
			<div className={`loadable position-relative transition ${this.props.loading ? 'loading' : ''}`}>
				{this.props.children}
				{this.props.loading && <i className="fa fa-3x fa-spinner fa-spin position-absolute text-muted"
																	style={{zIndex: 10, left: '45%', top: '45%'}}/>}
			</div>
		);
	}

}

export default Loadable;
