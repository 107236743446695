import React, {Component, Fragment} from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect, NavLink} from 'react-router-dom';
import Store from './Store';
import {observer} from "mobx-react";
import Login from "./pages/Login";
import Icon from "./components/Icon";
import Loader from "./components/Loader";
import {Button, Modal} from "react-bootstrap";
import Projects from "./pages/Projects";
import Users from "./pages/Users";
import Identifications from "./pages/Identifications";
import Dashboard from "./pages/Dashboard";
import Calendar from "./pages/Calendar";
import Info from "./pages/Info";
import Profile from "./pages/Profile";
import Activities from "./pages/Activities";
import Logo from "./components/Logo";

@observer
class App extends Component {

  componentDidMount() {
    try {
      const messaging = window.firebase.messaging();
      messaging.onMessage(payload => {
        console.log('Message received. ', payload);
        new Notification(payload.notification.title, payload.notification);
        if(payload.data.reload) window.location.reload();
      });
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    if (!Store.ready) return <div className="text-center p-5"><Loader/></div>;
    return (
      <Router>
        {Boolean(Store.user) &&
        <div className="container mb-2 mt-1">

          <ul className="nav">
            <li className="nav-item">
              <NavLink exact={true} to="/" className="nav-link">
                <Logo/>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink exact={true} to="/" className="nav-link"><Icon icon="home"/> Главная</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/activities" className="nav-link"><Icon icon="history"/> Активности</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/calendar" className="nav-link"><Icon icon="calendar"/> Календарь</NavLink>
            </li>

            {Boolean(Store.user.is_admin) &&
            <Fragment>
              <li className="nav-item">
                <NavLink to="/users" className="nav-link"><Icon icon="users"/> Сотрудники</NavLink>
              </li>
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/identifications" className="nav-link"><Icon icon="id-card-o"/> FacePass</NavLink>*/}
              {/*</li>*/}
              <li className="nav-item">
                <NavLink to="/projects" className="nav-link"><Icon icon="cubes"/> Проекты</NavLink>
              </li>
            </Fragment>}
            <li className="nav-item">
              <NavLink to="/info" className="nav-link"><Icon icon="info-circle"/> FAQ</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/profile" className="nav-link"><Icon icon="user"/> {Store.user.first_name}</NavLink>
            </li>
          </ul>
        </div>
        }

        <div className="container">
          {Store.user ?
            <Switch>
              <Route exact path="/" component={Dashboard}/>
              {Boolean(Store.user.is_admin) && <Route path="/projects" component={Projects}/>}
              {Boolean(Store.user.is_admin) && <Route path="/identifications" component={Identifications}/>}
              {Boolean(Store.user.is_admin) && <Route path="/users" component={Users}/>}
              <Route path="/calendar" component={Calendar}/>
              <Route path="/activities" component={Activities}/>
              <Route path="/info" component={Info}/>
              <Route path="/profile" component={Profile}/>
              <Redirect to="/"/>
            </Switch>
            :
            <Switch>
              <Route exact path="/login" component={Login}/>
              <Redirect to="/login"/>
            </Switch>
          }
        </div>
				{Store.modals.map((modal, idx) => (<Modal key={idx} show={true} onHide={()=>Store.modalResult(modal, false)}>

          {Boolean(modal.title) &&  <Modal.Header closeButton>
            <Modal.Title>{modal.title}</Modal.Title>
          </Modal.Header>}

          {Boolean(modal.body) && <Modal.Body>{modal.body}</Modal.Body>}
					<Modal.Footer>
            {modal.buttons.map((button, idx) => <Button key={idx} variant={button.variant}
                onClick={()=>Store.modalResult(modal, button.result)}>
              {Boolean(button.icon) && <Icon icon={button.icon} fixed={true} />}
              <span>{button.text}</span>
            </Button>)}
					</Modal.Footer>
				</Modal>))}
      </Router>
    )
  }
}

export default App;
