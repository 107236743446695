import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Icon from "./Icon";

class Pager extends Component {

	static propTypes = {
		meta: PropTypes.object.isRequired,
		onChangePage: PropTypes.func,
		// onChangeLimit: PropTypes.func,
	};

	next = async () => {
		if(this.props.meta.pages <= this.props.meta.page) return false;
		await this.props.onChangePage(this.props.meta.page + 1);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	prev = async () => {
		if(this.props.meta.page === 1) return false;
		await this.props.onChangePage(this.props.meta.page - 1);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	render() {

		return <div className="row">
			<div className="col-4">
				<button onClick={this.prev} disabled={this.props.meta.page <= 1}
						className="btn btn-primary mr-1">
					<Icon icon="arrow-left"/> Назад
				</button>
				<button onClick={this.next} disabled={this.props.meta.page >= this.props.meta.pages}
						className="btn btn-primary">
					Вперед <Icon icon="arrow-right"/>
				</button>
			</div>
			<div className="col-4 text-muted text-center pt-1">
				Страница <input style={{border: 0, paddind: 0, width: 50, textAlign: 'right', backgroundColor: 'transparent'}}
												min={1} max={this.props.meta.pages} step={1} type="number"
												onChange={e => this.props.onChangePage(Number(e.target.value))} value={this.props.meta.page}/> / {this.props.meta.pages}
			</div>
			<div className="col-4 text-muted text-right pt-1">
				Всего: {this.props.meta.count}
			</div>
		</div>

	}
}

export default Pager;
