import React, {Component} from 'react';
import api from "../utils/api";
import Store from "../Store";
import Icon from "../components/Icon";
import Loadable from "../components/Loadable";
import Logo from "../components/Logo";

class Login extends Component {

	state = {
		busy: false
	};

	login = async (e) => {
		e.preventDefault();
		try {
			this.setState({busy: true});
			const res = await api('users/login', {
				email: e.target.email.value,
				password: e.target.password.value,
			});
			localStorage.token = res.token;
			Store.token = res.token;
			Store.user = res.user;
			this.props.history.replace('/');
			return false;
		} catch (e) {
			await Store.modalAlert(e.message);
			this.setState({busy: false});
		}
	};

	render() {
		return (
			<div className="row">
				<div className="col-sm-12 offset-sm-0 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
					<Loadable loading={this.state.busy}>
					<form className="card shadow-sm" onSubmit={this.login} style={{marginTop: 200}}>
						<div className="card-header">
							<Logo/>
						</div>
						<div className="card-body">
							<div className="form-group position-relative">
								<label>E-mail</label>
								<input disabled={this.state.busy} type="email" name="email" className="form-control" style={{paddingLeft: 35}} required autoFocus/>
								<Icon icon="envelope" fixed style={{ position: 'absolute', top: 43, left: 10 }}/>
							</div>
							<div className="form-group position-relative">
								<label>Пароль</label>
								<input disabled={this.state.busy} type="password" name="password" className="form-control"  style={{paddingLeft: 35}}  required/>
								<Icon icon="lock" fixed style={{ position: 'absolute', top: 43, left: 10 }}/>
							</div>
						</div>
						<div className="card-footer text-right">
							<button disabled={this.state.busy} type="submit" className="btn btn-icon btn-primary">
								<Icon icon="sign-in"/> Войти
							</button>
						</div>
						{/*<button onClick={()=>this.fullScreen(document.getElementById('root'))} type="button" className="btn btn-icon btn-block btn-lg mt-4 btn-primary">*/}
						{/*	Полный экран ВКЛ*/}
						{/*</button>*/}
					</form>
					</Loadable>
				</div>
			</div>

		)
	}

	fullScreen(element) {
		if(element.requestFullscreen) {
			element.requestFullscreen();
		} else if(element.webkitrequestFullscreen) {
			element.webkitRequestFullscreen();
		} else if(element.mozRequestFullscreen) {
			element.mozRequestFullScreen();
		}
	}

}

export default Login;
