import {observable} from "mobx";
import api from "./utils/api";

class Store {

	@observable user = null;
	@observable ready = false;
	@observable modals = [];

	constructor() {
		this.ping().finally(() => {
			this.ready = true;
			setInterval(this.ping, 60000);
		});
	}

	ping = async () => {
		if(!localStorage.token) return;
		try {
			this.user = await api('users/profile');
		} catch (e) {
			console.error(e);
			if(e.code === 401) this.logout();
		}
	};

	logout() {
		this.user = null;
		api('users/logout').finally(() => {
			localStorage.removeItem('token');
		});
	};

	// -- Modals

	modalCreate(modal = {}) {
		return new Promise(resolve => {
			modal.resolve = resolve;
			this.modals.push(modal);
		});
	}

	modalConfirm(message, options = {}) {
		return this.modalCreate({
			title: options.title,
			body: message,
			buttons: [
				{ variant: options.cancelVariant || 'secondary', text: options.cancelText || 'Отмена', result: false },
				{ variant: options.confirmVariant || 'primary', text: options.confirmText || 'Да', icon: options.confirmIcon || null, result: true },
			],
		});
	}

	modalAlert(message, options = {}) {
		if(options.error) {
			options.title = 'Ошибка';
		}
		return this.modalCreate({
			title: options.title || null,
			body: message,
			buttons: [
				{ variant: options.buttonVariant || 'primary', text: options.buttonText || 'Закрыть', result: null },
			],
		});
	}

	modalResult(modal, result) {
		this.modals = this.modals.filter((m) => m !== modal);
		modal.resolve(result);
	}

	// -- Modals end

}

export default new Store();
