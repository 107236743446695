import React, {Component, Fragment} from 'react';
import {observer} from 'mobx-react';
import {action, observable, toJS} from 'mobx';
import api from "../../utils/api";
import Icon from "../../components/Icon";
import {Button, Modal} from "react-bootstrap";
import Loader from "../../components/Loader";
import ImageInput from "../../components/ImageInput";
import serialize from 'form-serialize'
import Form from 'react-bootstrap/Form'
import Store from './../../Store';
import User from "../../components/User";
import Loadable from "../../components/Loadable";
import Timeline from "../../components/Timeline";
import BoxItem from "../../components/BoxItem";

@observer
class Manage extends Component {

	static status = {
		active: { text: 'Работает' },
		blocked: { text: 'Блокирован' },
		fired: { text: 'Уволен' },
	};

	@observable ready = false;
	@observable loading = false;

	@observable data = [];
	@observable meta = {
		page: 1,
		limit: 20
	};

	@observable user = {};
	@observable editModalShow = false;

	constructor(props) {
		super(props);
		if(Store.users) {
			this.data = Store.users.data;
			this.meta = Store.users.meta;
			this.ready = true;
		}
	}

	@action stopActivity = async (activity) => {
		await api(`activity/${activity.id}/end`);
		await this.fetch();
	};

	async componentDidMount() {
		const params = this.props.match.params;
		if(params.action === 'edit') {
			this.edit(await api(`users/${params.id}`));
		}
		if(params.action === 'delete') {
			await this.delete(await api(`users/${params.id}`));
		}

		await this.fetch();
		this.ready = true;
	}

	@action fetch = async () => {
		try {
			this.loading = true;
			const { data, meta } = await api('users', this.meta);
			this.data = data;
			this.meta = meta;
			Store.users = { data, meta };
		} catch (e) {
			await Store.modalAlert(e.message);
		} finally {
			this.loading = false;
		}
	};

	@action edit = (user = {}) => {
		this.user = user;
		this.editModalShow = true;
	};

	@action submit = async (e) => {
		e.preventDefault();
		const data = serialize(e.target, true);
		const user = {...toJS(this.user), ...data};

		user.is_admin = Boolean(data.is_admin);
		user.tel = user.tel.replace(/[^0-9]/, '');
		user.facepass_id = Number(data.facepass_id) || null;
		if(!user.password) delete user.password;
		delete user.salary;
		try {
			this.loading = true;
			if(user.id) {
				await api(`users/${this.user.id}/update`, { user })
			} else {
				await api(`users/add`, { user })
			}
			this.editModalShow = false;
			await this.fetch();
		} catch (e) {
			await Store.modalAlert(e.message);
		} finally {
			this.loading = false;
		}
	};

	@action delete = async (user) => {

		if(!await Store.modalConfirm(`Вы действительно хотите удалить пользователя ${user.first_name} ${user.last_name}?`, {
				title: 'Осторожно!',
				confirmVariant: 'danger',
				confirmText: 'Удалить пользователя',
				confirmIcon: 'times'
		})) return;

		try {
			await api(`users/${user.id}/delete123`);
			await this.fetch();
		} catch (e) {
			await Store.modalAlert(e.message);
		}
	};

	render() {
		if (!this.ready) return <Loader/>;
		return (
			<Loadable loading={this.loading}>
				<div className="card shadow-sm">
					<table className="table table-striped mb-0">
						<thead>
						<tr>
							<th>Имя</th>
							<th>Что делает</th>
							<th>Активность сегодня</th>
							<th width={150}/>
						</tr>
						</thead>
						<tbody>
						{this.data.map(user => {
							return <tr key={user.id}>
								<td><User user={user}/></td>

									{user.status === 'active' ?
										<Fragment>
											<td>
											{user.activity ?
												<Fragment>
													{user.activity.type === 'idle' ? <p className="text-warning"><Icon icon="warning"/> Нет работы</p>
														: ''}
													{user.activity.type === 'task' ? <BoxItem item={user.activity.task}/> : ''}
													<button className="btn btn-sm btn-secondary" onClick={()=>this.stopActivity(user.activity)}><Icon icon="pause"/> Остановить</button>
												</Fragment>
												:
												<span className="text-danger"><Icon icon="coffee"/> Ничего не делает</span>
											}
											</td>
											<td><Timeline user={user.id}/></td>
										</Fragment>
										:
										<td colSpan={2} className="text-muted">
											<Icon icon="info-circle"/> {Manage.status[user.status].text}
										</td>
									}
								<td className="text-right">

									<button className="btn btn-primary btn-sm" onClick={() => this.edit(user)}>
										<Icon icon="edit"/>
									</button>

									<button className="ml-2 btn btn-danger btn-sm" onClick={() => this.delete(user)}>
										<Icon icon="times"/>
									</button>


								</td>
							</tr>
						})}
						</tbody>
					</table>
					<div className="card-footer">
						<button className="btn btn-primary" onClick={()=>this.edit()}>
							<Icon icon="plus-circle" />
							Добавить
						</button>
					</div>
				</div>

				<Modal show={this.editModalShow} onHide={() => this.editModalShow = false} size="lg">
					<form onSubmit={this.submit}>
						<Modal.Header closeButton>
							<Modal.Title>Учетная запись</Modal.Title>
						</Modal.Header>
						<Loadable loading={this.loading}>
						<Modal.Body>

							<div className="row">
								<div className="col-3">
									<div className="row">
										<div className="col">
											<div className="form-group">
												<label>Фото*</label>
												<ImageInput name="avatar" crop={true} cropper={{aspectRatio: 1}}
																		value={this.user.avatar} width={144} height={144}/>
											</div>

											<div className="form-group">
												<select required defaultValue={this.user.gender} name="gender" className="form-control">
													<option value="">-- Укажите пол --</option>
													<option value="male">Мужчина</option>
													<option value="female">Женщина</option>
												</select>
											</div>

											<div className="form-group">
												<select required defaultValue={this.user.status} name="status" className="form-control">
													<option value="">-- Укажите статус --</option>
													<option value="active">Работает</option>
													<option value="fired">Уволен</option>
													<option value="blocked">Блокирован</option>
												</select>
											</div>

											<Form.Group controlId="is_admin">
												<Form.Check type="checkbox" label="Администратор" name="is_admin" defaultChecked={this.user.is_admin} />
											</Form.Group>
										</div>
									</div>
								</div>
								<div className="col">

									<div className="row">
										<div className="col">
											<div className="form-group">
												<label>Фамилия*</label>
												<input type="text" maxLength="50" required defaultValue={this.user.last_name} name="last_name" className="form-control"/>
											</div>
										</div>
										<div className="col">
											<div className="form-group">
												<label>Имя*</label>
												<input type="text" maxLength="50" required defaultValue={this.user.first_name} name="first_name" className="form-control"/>
											</div>
										</div>
										<div className="col">
											<div className="form-group">
												<label>Отчество*</label>
												<input type="text" maxLength="50" required defaultValue={this.user.middle_name} name="middle_name" className="form-control"/>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-8">
											<div className="form-group">
												<label>Должность*</label>
												<input type="text" maxLength="50" required defaultValue={this.user.position} name="position" className="form-control"/>
											</div>
										</div>
										<div className="col">
											<div className="form-group">
												<label>FacePass ID</label>
												<input type="number" min={0} step={1} defaultValue={this.user.facepass_id} name="facepass_id" className="form-control"/>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col">
											<div className="form-group">
												<label>E-mail*</label>
												<input type="email" maxLength="50" required defaultValue={this.user.email} name="email" className="form-control"/>
											</div>
										</div>
										<div className="col">
											<div className="form-group">
												<label>Телефон*</label>
												<input type="tel" maxLength="20" required defaultValue={this.user.tel} name="tel" className="form-control"/>
											</div>
										</div>
										<div className="col">

											<div className="form-group">
												<label>Пароль</label>
												<input type="text" defaultValue="" name="password" className="form-control"/>
											</div>
										</div>
									</div>


									<div className="row">
										<div className="col-4">
											<div className="form-group">
												<label>Дата рождения*</label>
												<input type="date" required defaultValue={this.user.bdate} name="bdate" className="form-control"/>
											</div>
										</div>
										<div className="col-4">
											<div className="form-group">
												<label>Дата найма*</label>
												<input type="date" required defaultValue={this.user.hdate} name="hdate" className="form-control"/>
											</div>
										</div>
										<div className="col-4">
											<div className="form-group">
												<label>Дата увольнения</label>
												<input type="date" defaultValue={this.user.fdate} name="fdate" className="form-control"/>
											</div>
										</div>
									</div>

								</div>
							</div>

						</Modal.Body>
						</Loadable>
						<Modal.Footer>
							<Button variant="secondary" onClick={() => this.editModalShow = false} type="button">
								Отмена
							</Button>
							<Button disabled={this.loading} variant="primary" type="submit">
								<Icon icon="save"/>
								Сохранить
							</Button>
						</Modal.Footer>
					</form>
				</Modal>

			</Loadable>
		)
	}
}

//
// class Bars extends Component {
// 	render() {
// 		return <Icon onClick={this.props.onClick.bind(this)} style={{cursor: 'pointer'}} icon="bars"/>
// 	}
// }


export default Manage;
