import React, {Component, Fragment} from 'react';
import {observer} from 'mobx-react';
import {action, observable, toJS} from 'mobx';
import api from "../utils/api";
import Loader from "../components/Loader";
import Store from "../Store";
import Icon from "../components/Icon";
import Loadable from "../components/Loadable";
import {Button, Modal} from "react-bootstrap";
import serialize from "form-serialize";
import {numberToTime, timeToNumber} from "../utils/tools";


@observer
class Activities extends Component {

  @observable ready = false;
  @observable loading = false;

  @observable date = null;
  @observable data = [];

  @observable activity = {};
  @observable editModalShow = false;

  @observable tasks = [];

  constructor(props) {
    super(props);
    if (Store.activities) {
      this.date = Store.activities.date;
      this.data = Store.activities.data;
      this.ready = true;
    }
  }

  async componentDidMount() {
    this.tasks = await api('tasks/my', {actual: true});
    await this.fetch();
    this.ready = true;
  }

  @action fetch = async () => {
    try {
      this.loading = true;
      const {date, data} = await api(`activity`, {date: this.date});
      Store.activities = {date, data};
      this.date = date;
      this.data = data;
    } catch (e) {
      await Store.modalAlert(e.message);
    } finally {
      this.loading = false;
    }
  };


  @action edit = (activity = {}) => {
    this.activity = activity;
    this.editModalShow = true;
  };

  @action submit = async (e) => {
    e.preventDefault();
    const data = serialize(e.target, {empty: true});
    const activity = {...toJS(this.activity), ...data};
    activity.time_begin = timeToNumber(activity.time_begin);
    activity.time_end = timeToNumber(activity.time_end);
    activity.date = this.date;
    delete activity.editable;
    try {
      this.loading = true;
      if (activity.id) {
        await api(`activity/${this.activity.id}/update`, activity)
      } else {
        await api(`activity/add`, activity)
      }
      this.editModalShow = false;
      await this.fetch();
    } catch (e) {
      await Store.modalAlert(e.message);
    } finally {
      this.loading = false;
    }
  };

  @action delete = async (activity) => {

    if (!await Store.modalConfirm(`Вы действительно хотите удалить активность?`, {
      title: 'Осторожно!',
      confirmVariant: 'danger',
      confirmText: 'Удалить',
      confirmIcon: 'times'
    })) return;

    try {
      await api(`activity/${activity.id}/delete`);
      await this.fetch();
    } catch (e) {
      await Store.modalAlert(e.message);
    }
  };

  setDate = async (date) => {
    this.date = date;
    await this.fetch();
  };

  render() {
    if (!this.ready) return <Loader/>;
    return (
      <Loadable loading={this.loading}>
        <input required className="form-control w-25" type="date" value={this.date}
               onChange={e => this.setDate(e.target.value)}/>
        <div className="card shadow-sm">
          <table className="table table-striped">
            <thead>
            <tr>
              <th>Время</th>
              <th>Тип</th>
              <th>Описание</th>
              <th>Комментарий</th>
              <th/>
            </tr>
            </thead>
            <tbody>
            {this.data.map(activity => {
              return <tr key={activity.id}>
                <td>
                  <div>{numberToTime(activity.time_begin)} &mdash; {activity.time_end ? numberToTime(activity.time_end) : null}</div>
                  <div className="small text-muted">{activity.duration ? numberToTime(activity.duration) : null}</div>
                </td>
                <td>
                  {activity.type === 'task' && 'Задача'}
                  {activity.type === 'idle' && 'Нет работы'}
                </td>
                <td>{activity.task ? <Fragment>
                  <div>{activity.task.name}</div>
                  <div className="text-muted small">{activity.task.project.name}</div>
                </Fragment> : null}
                </td>
                <td>{activity.comment}</td>
                <td className="text-right">
                  {activity.editable && activity.time_end !== null ?
                    <Fragment>
                      <button className="btn btn-sm btn-primary" onClick={() => this.edit(activity)}>
                        <Icon icon="edit"/> Изменить
                      </button>
                      <button className="ml-2 btn btn-sm btn-danger" onClick={() => this.delete(activity)}>
                        <Icon icon="times"/>
                      </button>
                    </Fragment>
                    : null}
                </td>
              </tr>
            })}
            </tbody>
          </table>
          <div className="card-footer">
            <button type="button" className="btn btn-success" onClick={() => this.edit({type: 'task'})}>
              <Icon icon="plus-circle"/>
              Задача
            </button>
            <button type="button" className="btn btn-warning ml-1" onClick={() => this.edit({type: 'idle'})}>
              <Icon icon="clock-o"/>
              Нет работы
            </button>
          </div>
        </div>

        <Modal show={this.editModalShow} onHide={() => this.editModalShow = false}>
          <form onSubmit={this.submit}>
            <Modal.Header closeButton>
              <Modal.Title>
                {this.activity.type === 'task' && 'Задача'}
                {this.activity.type === 'idle' && 'Нет работы'}
              </Modal.Title>
            </Modal.Header>
            <Loadable loading={this.loading}>
              <Modal.Body>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label>Начало</label>
                      <input type="time" name="time_begin" min="08:00" max="20:00" required className="form-control"
                             defaultValue={this.activity.time_begin ? numberToTime(this.activity.time_begin) : ''}/>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label>Конец</label>
                      <input type="time" name="time_end" min="08:00" max="20:00" required className="form-control"
                             defaultValue={this.activity.time_end ? numberToTime(this.activity.time_end) : ''}/>
                    </div>
                  </div>
                </div>
                {this.activity.type === 'task' && !this.activity.id ?
                  <div className="form-group">
                    <label>Задача</label>
                    <select required name="task" className="form-control"
                            defaultValue={this.activity.task ? this.activity.task.id : null}>
                      {this.tasks.map(task => <option key={task.id}
                                                      value={task.id}>{task.name} ({task.project.name})</option>)}
                    </select>
                  </div>
                  : null}
                <div className="form-group">
                  <label>Комментарий</label>
                  <input type="text" name="comment" className="form-control"
                         defaultValue={this.activity.comment || ''}/>
                </div>
              </Modal.Body>
            </Loadable>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.editModalShow = false} type="button">
                Отмена
              </Button>
              <Button disabled={this.loading} variant="primary" type="submit">
                <Icon icon="save"/>
                Сохранить
              </Button>
            </Modal.Footer>
          </form>
        </Modal>

      </Loadable>
    )
  }
}


// class Bars extends Component {
//   render() {
//     return <Icon onClick={this.props.onClick.bind(this)} style={{cursor: 'pointer'}} icon="bars"/>
//   }
// }

export default Activities;
