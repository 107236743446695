import React, { Component } from 'react';
import {Button, Modal} from "react-bootstrap";
import Loadable from "../Loadable";
import Icon from "../Icon";
import {observer} from "mobx-react";
import {action, observable} from "mobx";
import api from "../../utils/api";
import Store from "../../Store";

@observer
class EditTask extends Component {

  @observable task = {};
  @observable showModal = false;
  @observable loading = false;
  @observable projects = [];

  resolve = ()=>{};

  componentDidMount = async () => {
    if(Store.projects) {
      this.projects = Store.projects;
    } else {
      this.loading = true;
      this.projects = Store.projects = await api('projects');
    }
    this.loading = false;
  };

  @action edit = (e) => {
    const task = {...this.task};
    const key = e.target.name;
    let value = e.target.value;
    value = value || null;
    task[key] = value;
    this.task = task;
  };

  @action close = () => {
    this.showModal = false;
    this.resolve(null);
  };

  createTask = () => {
    this.task = {};
    this.showModal = true;
    return new Promise(resolve => {
      this.resolve = resolve;
    });
  };

  editTask = async (id) => {
    this.task = await api('tasks/' + id);
    this.showModal = true;
    return new Promise(resolve => {
      this.resolve = resolve;
    });
  };

  @action submit = async (e) => {
    e.preventDefault();
    try {
      this.loading = true;
      const endpoint = this.task.id ? `tasks/${this.task.id}/update` : 'tasks/create';
      const task = await api(endpoint, { task: this.task });
      this.showModal = false;
      this.resolve(task);
    } catch (e) {
      await Store.modalAlert(e.message);
    } finally {
      this.loading = false;
    }
  };

  render() {
    return <Modal show={this.showModal} onHide={this.close}>
      <form onSubmit={this.submit}>
        <Modal.Header closeButton>
          <Modal.Title>{this.task.name || 'Задача'}</Modal.Title>
        </Modal.Header>
        <Loadable loading={this.loading}>
          <Modal.Body>
            {!this.task.id && <div className="form-group">
              <label>Проект</label>
              <select required value={this.task.project ? this.task.project.id : ''}
                      name="project" className="form-control" onChange={this.edit}>
                <option value="">-- Выберите --</option>
                {this.projects.map(project => <option value={project.id} key={project.id}>{project.name}</option>)}
              </select>
            </div>}
            <div className="form-group">
              <label>Название</label>
              <input type="text" maxLength={100} required value="Работа над пректом"
                name="name" className="form-control" readOnly
              />
            </div>
          </Modal.Body>
        </Loadable>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.close} type="button">
            Отмена
          </Button>
          <Button disabled={this.loading} variant="primary" type="submit">
            <Icon icon={this.task.id ? 'save' : 'plus-circle'}/>
            {this.task.id ? 'Сохранить' : 'Добавить'}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  }

}

export default EditTask;
