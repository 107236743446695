// export const endpoint = "https://erp.redstream.by/api/";
// export const cdn = "https://erp.redstream.by/";
export const endpoint = window.location.host.match(/^(localhost|192)/) ? 'http://localhost:9500/api/' : 'https://erp.redstream.by/api/';
export const cdn = window.location.host.match(/^(localhost|192)/) ? 'http://localhost:9500/' : 'https://erp.redstream.by/';

export default function api(method, args = {}, silent = false) {
	const token = localStorage.token || null;
	return new Promise((resolve, reject) => {
		function processReject(error) {
			if(!silent) reject(error);
			else resolve(false);
		}
		let body;
		if(args instanceof FormData) {
			body = args;
			if(token) args.append('token', token);
		} else {
			if(token) args.token = token;
			body = JSON.stringify(args)
		}
		fetch(endpoint + method, {
			method: 'POST',
			body
		}).then(data => {
			return data.json();
		}).then((data) => {
			if(data.hasOwnProperty('error')) processReject(data.error);
			else if(data.hasOwnProperty('result')) resolve(data.result);
			else processReject({ message: 'Invalid Server Response', code: 2 })
		}).catch((error) => {
			processReject({ message: 'Network Error', code: 1, raw: error});
		});
	});
}

