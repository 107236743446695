import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {action, observable} from "mobx";
import api from "../utils/api";
import BoxItem from "../components/BoxItem";
import EditTask from "../components/tasks/EditTask";
import Store from "../Store";
import Loadable from "../components/Loadable";
import Icon from "../components/Icon";
import Timeline from "../components/Timeline";

@observer
class Dashboard extends Component {

	@observable tasks = [];
	@observable loading = false;
	@observable showEditModal = false;
	@observable task = {};
	@observable activity = {};
	@observable desktopWindowsClientDownloadUrl = null;

	/** type {EditTask} */
	EditTask = null;

	@observable os = 'unknown';

	firebase = () => {
		return new Promise((resolve, reject) => {
			if ('Notification' in window) {
				const messaging = window.firebase.messaging();
				messaging.requestPermission().then(() => {
					messaging.getToken().then(token => {
						if(token) {
							resolve(token);
							api('users/profile/update', { fcm: token });
						} else reject('Токен не получен')
					}).catch(err => {
						reject('При получении токена произошла ошибка.', err);
					});
				}).catch(err => {
					reject('Не удалось получить разрешение на показ уведомлений.', err);
				});
			}
		})
	};

	@action switchTask = async (task) => {
		try {
			this.loading = true;
			await api(`activity/task/${task.id}`);
			this.task = task;
			await this.fetch();
		} catch (e) {
			await Store.modalAlert(e.message)
		} finally {
			this.loading = false;
		}
	};

	async componentDidMount() {
		if (navigator.appVersion.indexOf("Win")!==-1) this.os ="windows";
		else if (navigator.appVersion.indexOf("Mac")!==-1) this.os ="macos";
		else if (navigator.appVersion.indexOf("Linux")!==-1) this.os ="linux";

		await this.fetch();

		if(!Store.user.fcm) {
			this.firebase().then(console.log).catch(console.error);
		}
	}

	@action fetch = async () => {
		this.loading = true;
		this.tasks = await api('tasks/my', { actual: true });
		this.activity = await api('activity/current');

		if (this.os === "windows") {
			await fetch('https://api.github.com/repos/elfexor/erp-updates/releases/latest').then(response => response.json()).then(data => {
				this.desktopWindowsClientDownloadUrl = `https://github.com/elfexor/erp-updates/releases/download/v${data.name}/erp-redstream-Setup-${data.name}.exe`
			});
		}

		this.loading = false;
	};

	@action pauseWorking = async () => {
		await api('activity/pause');
		await this.fetch();
	};

	@action finishTask = async () => {
		const task = this.activity.task;
		if(!task) return false;
		if(!await Store.modalConfirm(`Задача будет завершена и помещена в архив`, {
			title: task.name,
			confirmVariant: 'success',
			confirmText: 'Задача выполнена',
			confirmIcon: 'check'
		})) return false;
		try {
			this.loading = true;
			await api('activity/confirm');
			await this.fetch();
		} catch (e) {
			await Store.modalAlert(e.message, { error: true });
		} finally {
			this.loading = false;
		}
	};

	createTask = () => {
		this.EditTask.createTask().then(this.fetch);
	};

	// editTask = (task) => {
	// 	this.EditTask.editTask(task.id).then(this.fetch);
	// };

	startIdle = async () => {
		await api('activity/idle');
		await this.fetch();
	};

	render() {
		return <Loadable loading={this.loading}>

			{this.os === 'linux' && <div className="alert alert-warning">
				<Icon icon="info-circle"/> Доступен desktop-клиент для Linux
				<a className="ml-3 btn btn-sm btn-success"  rel="noopener noreferrer"  href="https://snapcraft.io/erp-redstream" target="_blank"><Icon icon="download"/> Скачать</a>
			</div>}

			{this.os === 'windows' && this.desktopWindowsClientDownloadUrl && <div className="alert alert-warning">
				<Icon icon="info-circle"/> Доступен desktop-клиент для Windows
				<a className="ml-3 btn btn-sm btn-success"  rel="noopener noreferrer"  href={this.desktopWindowsClientDownloadUrl} target="_blank"><Icon icon="download"/> Скачать</a>
			</div>}

			<div className="text-muted font-weight-bold">Активность сегодня</div>
			<Timeline/>

			<div className="text-muted font-weight-bold mt-3">Текущая активность</div>
			<div className="activity">

				{!this.activity && <div className="activity-empty">
					<div className="text p-5 text-center text-muted">
						<p className="lead"><Icon icon="coffee"/> Сейчас вы ничего не делаете</p>
						<p>Это нормально, если вы еще не приступили к работе или у вас перерыв.</p>
						{this.tasks.length === 0 && <p className="mb-0">Если у вас нет работы, <u className="pointer" onClick={this.startIdle}>нажмите здесь</u></p>}
					</div>
				</div>
				}

				{this.activity && this.activity.task &&
					<div className="activity-task">
						<div className="p-2 ">
							<BoxItem onClick={this.pauseWorking} item={this.activity.task} />
						</div>
						<div className="ctrl">
							<button onClick={this.finishTask} className="btn btn-success ml-2 float-right"><Icon icon="check"/> Выполнено</button>
							<button onClick={this.pauseWorking} className="btn btn-secondary float-right"><Icon icon="pause"/> Пауза</button>
						</div>
					</div>
				}

				{this.activity && this.activity.type === 'idle' &&
				<div className="activity-idle">
					<div className="text p-5 text-center text-muted">
						<p className="lead"><Icon icon="info-circle"/> Нет работы</p>
						<p>Ничего страшного, так бывает. Посветите это время саморазвитию.</p>
						<p className="mb-0">Когда работа появится, или вы захотите сделать перерыв, <u className="pointer" onClick={this.pauseWorking}>нажмите здесь</u></p>
					</div>
				</div>
				}

			</div>


			<div className="mt-3 text-muted font-weight-bold">{this.activity ? 'Переключайтесь между задачами' : 'Выберите задачу для начала работы'}</div>
			<div className="tasks p-2">

				{this.tasks.length === 0 &&
				<div className="text p-4 text-center text-muted">
					<p className="lead"><Icon icon="warning"/> Список задач пуст</p>
					<p className="mb-0">Добавьте новую задачу, <u className="pointer" onClick={this.createTask}>нажав здесь</u></p>
				</div>}

				{this.tasks.map(task => <BoxItem key={task.id} onExtraClick={this.editTask} item={task} onClick={()=>this.switchTask(task)} />)}
				<div className="clearfix"/>
				<EditTask ref={EditTask=>this.EditTask=EditTask}/>
			</div>
			<div className="mt-2">
				<button className="btn btn-success" onClick={this.createTask}>
					<Icon icon="plus-circle"/>
					Новая задача
				</button>
				<button className="btn btn-warning ml-1" onClick={this.startIdle}>
					<Icon icon="clock-o"/>
					Нет работы
				</button>
			</div>
		</Loadable>
	}

}

export default Dashboard;
