export function range(n) {
	const arr = [];
	for(let i = 1; i <= n; i++) arr.push(i);
	return arr;
}

export function plural(val, str) {
	let  v = Math.abs(val) % 100, n = v % 10,
		p = (!n || n >= 5 || (v >= 5 && v <= 20)) ? 3 : ((n > 1 && n < 5) ? 2 : 1),
		s = str.split(',');
	return s[0] + s[p];
}

export function number_format(number, decimals = 0, dec_point = ',', thousands_sep = ' ') {
	let i, j, kw, kd, km;
	i = parseInt(number = (+number || 0).toFixed(decimals)) + '';
	if ((j = i.length) > 3) {
		j = j % 3;
	} else {
		j = 0;
	}
	km = (j ? i.substr(0, j) + thousands_sep : "");
	kw = i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands_sep);
	kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).replace(/-/, 0).slice(2) : "");
	return km + kw + kd;
}

export function bytes(size) {
	if(size < 1024) return `${number_format(size)} байт`;
	if(size < 1024 * 1024) return `${number_format(size / 1024, 2)} Кб`;
	return `${number_format(size / 1024 / 1024, 2)} Мб`;
}

export function gender(user, male, female) {
	if(!user) return male;
	if(user === 'female' || user.gender === 'female') return female;
	return male;
}

export function timeToNumber(time) {
	const n = time.split(':');
	let sec = Number(n[0]) * 3600 + Number(n[1]) * 60;
	if(n[2]) sec += Number(n[2]);
	return sec;
}

export function numberToTime(s, showSeconds = false) {
	s = Number(s);
	let h = 0, m = 0;
	if(s >= 60) {
		m = Math.floor(s / 60);
		s -= m * 60;
	}
	if(m >= 60) {
		h = Math.floor(m / 60);
		m -= h * 60;
	}
	let str = zeroFill(h, 2) + ':' + zeroFill(m, 2);
	if(showSeconds) str += ':' + zeroFill(s, 2);
	return str;
}

export function zeroFill(number, width) {
	number = Number(number);
	width -= number.toString().length;
	if (width > 0) {
		return new Array(width + (/\./.test( number ) ? 2 : 1)).join('0') + number;
	}
	return number + ""; // always return a string
}
