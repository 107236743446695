import React, {Component} from 'react';
import Icon from "../components/Icon";
import {observable} from "mobx";
import {observer} from "mobx-react";

@observer
class Info extends Component {

	@observable
	desktopWindowsClientDownloadUrl = 'https://github.com/elfexor/erp-updates/releases/latest';

	componentDidMount() {
		fetch('https://api.github.com/repos/elfexor/erp-updates/releases/latest').then(response => response.json()).then(data => {
			this.desktopWindowsClientDownloadUrl = `https://github.com/elfexor/erp-updates/releases/download/v${data.name}/erp-redstream-Setup-${data.name}.exe`
		});
	}

	render() {
		return (
			<div className="card shadow-sm mb-5">
				<div className="card-header bg-white border-bottom-0 font-weight-bold">
					<Icon icon="info-circle"/>
					Информация
				</div>
				<div className="card-body">

					<h4>Что это?</h4>
					<p>RedStream ERP &mdash; внутренняя система учета рабочего времени для формирования отчетности перед головным офисом.</p>

					<h4>Зачем?</h4>
					<p>Так как один и тот же сотрудник может работать на нескольких проектах, нам очень важно
						разнести расходы по проектам пропорционально затраченному времени сотрудника.</p>

					<h4>Как все устроено?</h4>
					<p>На главной странице отражена Ваша активность. В течение дня Вы работаете над проектами, переключаетесь между ними,
						делаете перерывы. Система регистрирует какую долю времени вы провели на том или ином проекте.</p>

					<p>Если по какой-либо причине Вы сидите без работы нажмите на кнопку &laquo;Нет работы&raquo;.
						Этим Вы укажите системе что у вас вынужденный простой.</p>

					<h4>Очень важно!</h4>
					<p>Своевременно регистрируйте свои активности (берите проект в работу и переключайтесь между ними). Не забывайте
						ставить проект на паузу, когда вы покидаете рабочее место (перерыв, обед или конец рабочего дня).</p>

					<p>Если у вас вынужденный простой в работе &mdash; посвятите это время саморазвитию, почитайте полезные статьи,
						посмотрите что-то полезное на ютубчике. Не забывайте нажимать на кнопку &laquo;Нет работы&raquo;, если вы сели поработать но Вам
						реально нечем заняться. Если Вы покидаете рабочее место, обязательно отключайте режим &laquo;Нет работы&raquo;,
						даже если ее действительно нет.
					</p>

					<p>Не старайтесь &laquo;забить&raquo; весь день надуманными активностями.
						Помимо описанных целей выше, данный инструмент нужен так же для поднятия продуктивности нашего офиса в целом.
						Мы хотим видеть реальную картину работы, стараться делать RedStream лучше. Несмотря на то, что мы на
						хорошем счету у AdCombo, нужно не просто &laquo;держать марку&raquo;, но и уверенно расти дальше.</p>

					<h4>Desktop ERP</h4>
					<p>Выберите вашу платформу, чтобы скачать клиент</p>
					<p>
						<a className="btn btn-success"  rel="noopener noreferrer"  href={this.desktopWindowsClientDownloadUrl} target="_blank">
							<Icon icon="download"/> Windows
						</a>
						<a className="btn btn-success ml-2"  rel="noopener noreferrer"  href="https://snapcraft.io/erp-redstream" target="_blank">
							<Icon icon="download"/> Linux
						</a>
						<button disabled className="btn btn-outline-secondary ml-2">
							<Icon icon="download"/> MacOS
						</button>
					</p>
				</div>
			</div>
		)
	}
}

export default Info;
