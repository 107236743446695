import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {action, observable, toJS} from 'mobx';
import api from "../utils/api";
import Icon from "../components/Icon";
import {Button, Modal} from "react-bootstrap";
import Loader from "../components/Loader";
import ImageInput from "../components/ImageInput";
import { ChromePicker } from 'react-color'
import Store from "../Store";
import Loadable from "../components/Loadable";

@observer
class Projects extends Component {

	@observable ready = false;
	@observable loading = false;

	@observable data = [];
	@observable project = {};
	@observable editModalShow = false;

	constructor(props) {
		super(props);
		if(Store.projects) {
			this.data = Store.projects;
			this.ready = true;
		}
	}

	async componentDidMount() {
		await this.fetch();
		this.ready = true;
	}

	@action fetch = async () => {
		try {
			this.loading = true;
			this.data = await api(`projects`);
			Store.projects = this.data;
		} catch (e) {
			await Store.modalAlert(e.message);
		} finally {
			this.loading = false;
		}
	};

	@action edit = (project = {}) => {
		this.project = project;
		this.editModalShow = true;
	};

	@action submit = async (e) => {
		e.preventDefault();
		const project = {...toJS(this.project)};
		project.name = e.target.name.value;
		project.logo = e.target.logo.value || null;
		try {
			this.loading = true;
			if(this.project.id) {
				await api(`projects/${this.project.id}/update`, { project })
			} else {
				await api(`projects/add`, { project })
			}
			this.editModalShow = false;
			await this.fetch();
		} catch (e) {
			await Store.modalAlert(e.message);
		} finally {
			this.loading = false;
		}
	};

	@action delete = async (project) => {
		if(!await Store.modalConfirm(`Удалить проект ${project.name}?`, {
				title: 'Одумайся!',
				confirmVariant: 'danger',
				confirmText: 'Удалить',
				confirmIcon: 'times'
		})) return;

		try {
			await api(`projects/${project.id}/delete`);
			await this.fetch();
		} catch (e) {
			await Store.modalAlert(e.message);
		}
	};

	render() {
		if (!this.ready) return <Loader/>;
		return (
			<Loadable loading={this.loading}>
				<div className="card shadow-sm">
					<table className="table table-striped mb-0">
						<thead>
						<tr>
							<th>Название</th>
							<th width={150}/>
						</tr>
						</thead>
						<tbody>
						{this.data.map(project => {
							return <tr key={project.id}>
								<td><img alt={project.name} src={project.logo.url} width={24}/> {project.name}</td>
								<td className="text-right">
									<button onClick={() => this.edit(project)} className="btn btn-sm btn-primary mr-1">
										<Icon icon="edit"/>
									</button>
									<button onClick={() => this.delete(project)} className="btn btn-sm btn-danger">
										<Icon icon="times"/>
									</button>
								</td>
							</tr>
						})}
						</tbody>
					</table>
					<div className="card-footer">
						<button className="btn btn-primary" onClick={()=>this.edit()}>
							<Icon icon="plus-circle" />
							Добавить проект
						</button>
					</div>
				</div>

				<Modal show={this.editModalShow} onHide={() => this.editModalShow = false}>
					<form onSubmit={this.submit}>
						<Modal.Header closeButton>
							<Modal.Title>Проект</Modal.Title>
						</Modal.Header>
						<Loadable loading={this.loading}>
						<Modal.Body>
							<div className="form-group">
								<label>Название</label>
								<input type="text" maxLength="20" required defaultValue={this.project.name}
									   name="name" className="form-control"/>
							</div>
							<div className="form-group">
								<label>Цвет</label>
									<ChromePicker triangle="hide" color={this.project.color}
										 onChange={(color) => this.project.color = color.hex} />
							</div>
							<div className="form-group">
								<label>Лого</label>
								<ImageInput name="logo" crop={true} cropper={{aspectRatio: 1}}
									value={this.project.logo} width={64} height={64}/>
							</div>
						</Modal.Body>
						</Loadable>
						<Modal.Footer>
							<Button variant="secondary" onClick={() => this.editModalShow = false} type="button">
								Отмена
							</Button>
							<Button disabled={this.loading} variant="primary" type="submit">
								<Icon icon="save"/>
								Сохранить
							</Button>
						</Modal.Footer>
					</form>
				</Modal>

			</Loadable>
		)
	}
}

export default Projects;
