import React, {Component, Fragment} from 'react';
import {observer} from 'mobx-react';
import {action, observable} from 'mobx';
import api from "../utils/api";
import Loader from "../components/Loader";
import Store from "../Store";
import Icon from "../components/Icon";
import User from "../components/User";
import {gender} from "../utils/tools";
import Pager from "../components/Pager";
import moment from "moment";
import Loadable from "../components/Loadable";

@observer
class Identifications extends Component {

	@observable ready = false;
	@observable loading = false;

	@observable data = [];
	@observable meta = {
		page: 1,
		limit: 20
	};

	refreshInterval;

	constructor(props) {
		super(props);
		if(Store.identifications) {
			const { meta, data } = Store.identifications;
			this.meta = meta;
			this.data = data;
			this.ready = true;
		}
	}

	async componentDidMount() {
		await this.fetch();
		this.ready = true;
		this.refreshInterval = setInterval(this.fetch, 60000);
	}

	componentWillUnmount() {
		clearInterval(this.refreshInterval);
	}

	@action fetch = async () => {
		try {
			this.loading = true;
			const res = await api(`identifications`, this.meta);
			this.data = res.data;
			this.meta = res.meta;
			Store.identifications = res;
		} catch (e) {
			await Store.modalAlert(e.message);
		} finally {
			this.loading = false;
		}
	};

	render() {
		if (!this.ready) return <Loader/>;
		return (
			<Loadable loading={this.loading}>
				<div className="card shadow-sm">
					<table className="table table-striped">
						<thead>
						<tr>
							<th>Дата</th>
							<th>Сотрудник</th>
							<th>Событие</th>
						</tr>
						</thead>
						<tbody>
						{this.data.map(record => {
							return <tr key={record.id}>
								<td>{moment(record.date).format('DD.MM.YYYY, HH:mm')}</td>
								<td><User user={record.user} /></td>
								<td>{record.dir === 'in' ?
									<Fragment>
										<span className="text-success">
											<Icon icon="sign-in"/>
											{gender(record.user, 'Пришел', 'Пришла')}
										</span>
									</Fragment> :
									<Fragment>
										<span className="text-danger">
											<Icon icon="sign-out"/>
											{gender(record.user, 'Ушел', 'Ушла')}
										</span>
									</Fragment>
								}
								</td>
							</tr>
						})}
						</tbody>
					</table>
					<div className="card-footer">
						<Pager meta={this.meta} onChangePage={async page => { this.meta.page = page; await this.fetch() }}/>
					</div>
				</div>

			</Loadable>
		)
	}
}

export default Identifications;
