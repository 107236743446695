import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {action, observable, toJS} from 'mobx';
import Icon from "../components/Icon";
import Store from "../Store";
import api from "../utils/api";
import Loadable from "../components/Loadable";
import ImageInput from "../components/ImageInput";
import Loader from "../components/Loader";
import moment from "moment";

@observer
class Profile extends Component {

	@observable profile = null;
	@observable loading = false;
	@observable message = null;

	componentDidMount() {
		this.profile = toJS(Store.user);
	}

	@action change = (e) => {
		const key = e.target.name;
		let value = e.target.value;
		const profile = toJS(this.profile);

		if(key === 'tel') {
			value = value.replace(/[^0-9]/g, '');
			value = value.replace(/^8/, '375');
		}

		profile[key] = value || null;
		this.profile = profile;
		this.message = null;
	};

	@action submit = async (e) => {
		e.preventDefault();
		try {
			this.loading = true;
			Store.user = await api('users/profile/update', this.profile);
			this.message = 'Изменения успешно сохранены';
			this.profile.password = '';
		} catch (e) {
			await Store.modalAlert(e.message);
		} finally {
			this.loading = false;
		}

	};

	render() {
		if(!this.profile) return <Loader/>;
		return (
			<Loadable loading={this.loading}>
				<form onSubmit={this.submit} className="card shadow-sm" style={{maxWidth:500}}>
					<div className="card-header bg-white border-bottom-0 font-weight-bold">
						<Icon icon="user"/> Профиль
					</div>
					<div className="card-body">
						<div className="row">
							<div className="col-3">
								<ImageInput width={90} height={90} crop={true} cropper={{aspectRatio:1}}
									onChange={img=>this.profile.avatar=img} value={this.profile.avatar}/>
							</div>
							<div className="col">
								<div className="font-weight-bold">{this.profile.first_name} {this.profile.last_name}</div>
								<div className="small">{this.profile.email}</div>
								<div className="small text-muted">{moment(this.profile.bdate).format('DD.MM.YYYY')}</div>
							</div>
						</div>
						<div className="form-group mt-2">
							<label className="font-weight-bold">Мобильный телефон*</label>
							<input type="tel" className="form-control" required
								pattern="^375(25|29|33|44)[0-9]{7}$" name="tel"
								placeholder="375259261122"
								value={this.profile.tel || ''}
								onChange={this.change}
							/>
							<span className="small text-muted">В международном формате, например 375295556677</span>
						</div>

						<div className="form-group">
							<label className="font-weight-bold">Новый пароль</label>
							<input type="text" className="form-control" name="password"
								placeholder="******"
								value={this.profile.password || ''}
								onChange={this.change}
							/>
						</div>

						{Boolean(this.message) && <div className="small text-success">{this.message}</div>}
					</div>
					<div className="card-footer">
						<button className="btn btn-primary">
							<Icon icon="save"/> Сохранить
						</button>
						{/*<button onClick={() => window.history.back()} className="btn btn-outline-secondary">*/}
						{/*	<Icon icon="arrow-left"/> Назад*/}
						{/*</button>*/}

						<button type="button" className="btn btn-link text-danger float-right" onClick={()=>Store.logout()}>
							<Icon icon="power-off" />
							Выйти
						</button>

					</div>
				</form>
			</Loadable>

		)
	}
}

export default Profile;
