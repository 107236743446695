import React, {Component} from 'react';
import {observer} from 'mobx-react';
import PropTypes from "prop-types";
import Icon from "./Icon";

@observer
class BoxItem extends Component {

  static defaultProps = {
    onClick: ()=>{},
    onExtraClick: null,
    checked: false,
  };

  static propTypes = {
    checked: PropTypes.bool,
    onClick: PropTypes.func,
    onExtraClick: PropTypes.func,
  };

  render() {
    const item = this.props.item;
    return <div key={item.id} className="card pointer position-relative shadow-sm mr-2 mb-2" style={{width:220, height:150}}  onClick={this.props.onClick}>
      {item.project ?
        <div className="card-header" style={{backgroundColor: item.project.color}}>
          <img src={item.project.logo.url} alt={item.project.name} width={16}/>&nbsp;
          {item.project.name}
        </div>
        :
        null
      }
      <div className="card-body" style={{maxHeight:70, overflow: 'hidden'}}>
        {item.name}
      </div>
      {Boolean(this.props.onExtraClick) &&
      <div className="position-absolute" style={{right: 12, top: 12 }}>
        <Icon icon="bars" onClick={e=>{e.stopPropagation();this.props.onExtraClick(item)}}/>
      </div>}
    </div>
  }

}

export default BoxItem;
