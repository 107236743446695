import React, {Component} from 'react';
import PropTypes from 'prop-types';
import api from "../utils/api";
import {observer} from "mobx-react";
import {action, observable} from "mobx";
import Loader from "./Loader";
//import moment from "moment";

@observer
class Timeline extends Component {

	static propTypes = {
		user: PropTypes.number,
		date: PropTypes.string
	};

	static defaultProps = {
		user: null,
		date: null
	};

	interval = null;

	@observable date;
	@observable user;

	@observable data = {};
	@observable ready = false;

	constructor(props) {
		super(props);
		this.date = props.date;
		this.user = props.user;
	}

	componentDidMount = async () => {
		await this.fetch();
		this.ready = true;
		this.interval = setInterval(this.fetch, 60000);
	};

	componentDidUpdate = async (prev) => {
		console.log(prev, this.props);
		if(this.props.date !== prev.date) {
			this.date = this.props.date;
			this.user = this.props.user;
			await this.fetch();
		}
	};

	@action fetch = async () => {
		this.data = await api('calendar/timeline', {
			user: this.user,
			date: this.date
		});
	};

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	render() {
		if(!this.ready) return <Loader/>;
		const max = 3600 * 24;
		return <div style={{overflow:'hidden'}}>
			<div className="progress" style={{
				width:'195%',
				position: 'relative', height: 30, border: '1px solid #aaa', backgroundColor: '#aaa',
				left: '-60%'
			}}>
				<div className="progress-bar" title="Рабочее время" style={{
					opacity: 0.9, backgroundColor: '#fff', position: 'absolute', top: 1, bottom: 1,
					left: ((9*3600)/max*100) + '%',
					width: ((4*3600)/max*100) + '%'
				}}/>
				<div className="progress-bar" title="Обед" style={{
					opacity: 0.9, backgroundColor: '#efefef', position: 'absolute', top: 1, bottom: 1,
					left: ((13*3600)/max*100) + '%',
					width: ((3600)/max*100) + '%'
				}}/>
				<div className="progress-bar" title="Рабочее время" style={{
					opacity: 0.9, backgroundColor: '#fff', position: 'absolute', top: 1, bottom: 1,
					left: ((14*3600)/max*100) + '%',
					width: ((4*3600)/max*100) + '%'
				}}/>
				{this.data.timeline.map(activity => (
					<div
							 //title={`${activity.task ? activity.task.name || activity.type : activity.type} ${moment(activity.time_begin * 1000).format('HH:mm')} - ${moment(activity.time_end * 1000).format('HH:mm')}`}
							 title={`${activity.task ? activity.task.name || activity.type : activity.type}`}
							 className="progress-bar"
							 style={{
								 position: 'absolute',
								 backgroundColor: activity.type === 'task' ? "green" : 'orange',
								 top: 3,
								 bottom: 3,
								 cursor: "help",
								 left: ((activity.time_begin)/max*100) + '%',
								 width: ((activity.duration)/max*100) + '%'
							 }}
							 key={activity.id}
					/>
				))}
			</div>
		</div>
	}
}

export default Timeline;
