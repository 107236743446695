import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {action, observable, toJS} from 'mobx';
import api from "../../utils/api";
import Loader from "../../components/Loader";
import User from "../../components/User";
import Icon from "../../components/Icon";
import {Button, Modal} from "react-bootstrap";
import Store from "../../Store";
import moment from "moment";
import Loadable from "../../components/Loadable";
import Timeline from "../../components/Timeline";

@observer
class Profile extends Component {

	@observable id = null;
	@observable ready = false;
	@observable loading = false;

	@observable user = {};
	@observable salary = [];

	@observable showSalaryModal = false;
	@observable currentSalary = {};

	@observable date = null;

	constructor(props) {
		super(props);
		this.id = this.props.id || this.props.match.params.id
	}

	async componentDidMount() {
		await this.fetch(this.id);
		this.ready = true;
	}

	@action changeDate = (e) => {
		this.date = e.target.value || null;
		console.log(this.date);
	};

	@action fetch = async () => {
		try {
			this.loading = true;
			this.user = await api(`users/${this.id}`);
			this.salary = await api(`users/${this.id}/salary`);
		} catch (e) {
			await Store.modalAlert(e.message);
		} finally {
			this.loading = false;
		}
	};

	@action addSalary = () => {
		this.showSalaryModal = true;
		this.currentSalary = {
			id: null,
			hard: 0,
			soft: 0
		};
	};

	@action editSalary = (salary) => {
		this.currentSalary = {...toJS(salary)};
		this.showSalaryModal = true;
	};

	@action deleteSalary = async (salary) => {
		if(!await Store.modalConfirm('Удалить?')) return false;
		try {
			await api(`users/${this.user.id}/salary/${salary.id}/delete`);
			await this.fetch();
		} catch (e) {
			await Store.modalAlert(e.message);
		} finally {
			this.loading = false;
		}
	};

	@action submitSalary = async (e) => {
		e.preventDefault();
		try {
			const salary = this.currentSalary;
			this.loading = true;
			const endpoint = salary.id ? `${salary.id}/update` : 'add';
			await api(`users/${this.user.id}/salary/${endpoint}`, salary);
			await this.fetch();
			this.showSalaryModal = false;
		} catch (e) {
			await Store.modalAlert(e.message);
		} finally {
			this.loading = false;
		}
	};

	@action changeSalary = (e) => {
		const key = e.target.name;
		let value = e.target.value;
		const salary = {...toJS(this.currentSalary)};
		if(key === 'hard' || key === 'soft') value = Number(value) || 0;
		salary[key] = value;
		this.currentSalary = salary;
	};

	render() {
		if (!this.ready) return <Loader/>;
		return (
			<Loadable loading={this.loading}>
			<div className="card">
				<div className="card-body">
					<div className="row mb-3">
						<div className="col-4">
							<User user={this.user} onClick={console.log}/>
						</div>
						<div className="col">
								<div className=""><Icon icon="envelope-o"/> {this.user.email}</div>
								<div className=""><Icon icon="phone"/> {this.user.tel}</div>
						</div>
						<div className="col">
							<div title="Дата рождения"><Icon icon="calendar"/> {moment(this.user.bdate).format('DD.MM.YYYY')}</div>
							<div title="Дата найма"><Icon icon="building-o"/> {moment(this.user.hdate).format('DD.MM.YYYY')}</div>
						</div>
					</div>

					<div className="row">
						<div className="col-3">
							<input type="date" className="form-control" value={this.date || ''} onChange={this.changeDate}/>
						</div>
						<div className="col">
							<Timeline user={this.user.id} date={this.date}/>
						</div>
					</div>


					<div className="card mt-3">
						<div className="card-header">
							<b><Icon icon="money"/> Зарплата</b>
						</div>
						<table className="table table-striped mb-0">
							<thead>
							<tr>
								<th>Дата</th>
								<th className="text-right">Хард</th>
								<th className="text-right">Софт</th>
								<th className="text-right">Total</th>
								<th/>
							</tr>
							</thead>
							<tbody>
							{this.salary.map(salary => <tr key={salary.id}>
								<td className="font-weight-bold">{moment(salary.date).format('DD.MM.YYYY')}</td>
								<td className="text-right">{salary.hard}</td>
								<td className="text-right">{salary.soft}</td>
								<td className="font-weight-bold text-right">{salary.total} <span className="small text-muted">USD</span></td>
								<td className="text-right">
									<Icon icon="edit text-primary" onClick={()=>this.editSalary(salary)}/>
									<Icon icon="times text-danger" onClick={()=>this.deleteSalary(salary)}/>
								</td>
							</tr>)}
							</tbody>
						</table>
						<div className="card-footer">
							<button className="btn btn-primary btn-sm" onClick={this.addSalary}>
								<Icon icon="plus-circle"/> Добавить
							</button>
						</div>
					</div>

				</div>
				<div className="card-footer">
					<button onClick={() => window.history.back()} className="btn btn-outline-secondary">
						<Icon icon="arrow-left"/> Назад
					</button>
				</div>

				<Modal show={this.showSalaryModal} onHide={()=>this.showSalaryModal=false}>
					<form onSubmit={this.submitSalary}>
						<Modal.Header>
							<Modal.Title>Зарплата</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className="form-group">
								<label className="font-weight-bold">Дата установки*</label>
								<input required type="date" value={this.currentSalary.date || ''} name="date" onChange={this.changeSalary}
									className="form-control"/>
							</div>

							<div className="row">
								<div className="col-4">
									<div className="form-group">
										<label className="font-weight-bold">Хард*</label>
											<input type="number" min={0} step={1} value={Number(this.currentSalary.hard) || ''} name="hard" onChange={this.changeSalary}
												className="form-control"/>
									</div>
								</div>
								<div className="col-4">
									<div className="form-group">
										<label className="font-weight-bold">Софт*</label>
											<input type="number" min={0} step={1} value={Number(this.currentSalary.soft) || ''} name="soft" onChange={this.changeSalary}
														 className="form-control"/>
									</div>
								</div>
								<div className="col-4">
									<div className="form-group">
										<label className="font-weight-bold">Total</label>
										<div className="input-group">
											<input type="number" value={this.currentSalary.hard+this.currentSalary.soft} readOnly
														 className="form-control"/>
											<div className="input-group-append">
												<span className="input-group-text">USD</span>
											</div>
										</div>
									</div>
								</div>
							</div>



						</Modal.Body>
						<Modal.Footer>
							<Button variant="success" type="submit">
								<Icon icon="save"/>
								Сохранить
							</Button>
							<Button variant="secondary" type="button" onClick={()=>this.showSalaryModal=false}>
								Закрыть
							</Button>
						</Modal.Footer>
					</form>
				</Modal>

			</div>
			</Loadable>

		)
	}
}

export default Profile;
