import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import Store from "../Store";
import Profile from "./Users/Profile";
import Manage from "./Users/Manage";

class Users extends Component {

	render() {
		return (
			<Switch>
				{Boolean(Store.user.is_admin) && <Route path="/users/:id" exact component={Profile}/>}
				{Boolean(Store.user.is_admin) && <Route path="/users/:id/:action" exact component={Manage}/>}
				{Boolean(Store.user.is_admin) && <Route path="/users" component={Manage}/>}
			</Switch>
		)
	}

}

export default Users;
