import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from "mobx-react";
import {observable, action} from "mobx";
import Loader from "./Loader";
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import default_stub from '../images/no-pic.svg';
import api from "../utils/api";
import {Modal} from "react-bootstrap";
import "./ImageInput.css";
import Icon from "./Icon";

@observer
class ImageInput extends Component {

	static defaultProps = {
		name: 'image',
		value: '',
		width: 150,
		height: 150,
		stub: default_stub,
		onChange: null,
		crop: false,
		cropper: {}
	};

	static propTypes = {
		name: PropTypes.string,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		width: PropTypes.number,
		height: PropTypes.number,
		stub: PropTypes.string,
		onChange: PropTypes.func,
		crop: PropTypes.bool,
		cropper: PropTypes.object
	};

	cropperDefaults = {
		aspectRatio: null,
		style: {
			width: 465,
			height: 465,
		}
	};

	stub = default_stub;
	file = null;

	@observable url = null;
	@observable value = null;
	@observable upload = {};
	@observable loading = true;
	@observable showModal = false;
	croppedImage = null;

	openFile = () => {
		// noinspection JSDeprecatedSymbols
		this.refs.file.click();
	};

	componentDidMount = async () => {
		this.stub = this.props.stub || default_stub;
		const value = this.props.value;
		// if value is string with UUID
		if(String(this.props.value).length === 36) {
			this.value = this.props.value;
			this.upload = await api('uploads/' + this.value);
			this.url = this.upload.url;

		// if value is object { id: UUID, url: ... }
		} else if(typeof value === 'object' && value !== null) {

			this.upload = value;
			this.value = this.upload.id;
			this.url = this.upload.url;

		// if no value provided
		} else {
			this.url = this.stub;
		}

		this.loading = false;

	};

	readFile = async (e) => {
		this.file = e.target.files[0];

		if(this.props.crop) {
			const reader = new FileReader();
			reader.onload = async (event) => {
				this.croppedImage = event.target.result;
				this.showModal = true;
			};
			reader.readAsDataURL(this.file);
		} else {
			this.loading = true;
			const form = new FormData();
			form.append('image', this.file);
			form.append('extra', '1');
			const res = await api('uploads/upload', form);
			this.upload = res.image;
			this.url = this.upload.url;
			this.value = this.upload.id;
			this.loading = false;
			if(this.props.onChange) this.props.onChange(this.upload);
		}
	};

	clear = () => {
		this.upload = {};
		this.url = this.stub;
		this.value = '';
		if(this.props.onChange) this.props.onChange(null);
	};

	@action closeModal = () => {
		this.showModal = false;
	};

	@action crop = async () => {
		// noinspection JSDeprecatedSymbols,JSUnresolvedFunction
		this.croppedImage = this.refs.cropper.getCroppedCanvas().toDataURL();
	};

	@action save = async () => {
		this.closeModal();
		this.loading = true;
		const res = await api('uploads/upload', {
			uploads: {
				image: {
					data: this.croppedImage,
					name: this.file.name
				}
			},
			extra: true
		});
		this.url = this.croppedImage;
		this.upload = res.image;
		this.value = this.upload.id;
		this.loading = false;
		if(this.props.onChange) this.props.onChange(this.upload);
	};

	render() {
		if(this.loading) return <Loader/>;
		return <div className="image-input ctrl-hover" style={{width:this.props.width, height:this.props.height}}>
			{this.value && <div className="ctrl"><i className="fa fa-times fa-fw text-danger btn-mini" onClick={this.clear}/></div>}
			<input type="file" onChange={this.readFile} accept="image/png,image/jpeg" ref="file"/>
			<img title="Выбрать изображение" alt="" src={this.url} ref="image" onClick={this.openFile}/>
			{this.props.name ? <input type="hidden" name={this.props.name || ''} onChange={()=>{}} value={this.value || ''}/> : null}
			{this.props.crop ?
				<Modal show={this.showModal} onHide={this.closeModal}>
					<Modal.Header closeButton>
						<Modal.Title>Обрезка изображения</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Cropper {...this.cropperDefaults} {...this.props.cropper} ref="cropper" crop={this.crop} src={this.croppedImage}/>
					</Modal.Body>
					<Modal.Footer>
						<button className="btn btn-primary" onClick={this.save}><Icon icon="crop"/> Обрезать</button>
						<button className="btn btn-secondary" onClick={this.closeModal}>Отменить</button>
					</Modal.Footer>
				</Modal>




				:null
			}
		</div>
	}
}

export default ImageInput;
